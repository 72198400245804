import React from 'react';

type MenuCloseProps = {
  className?: string;
  color: string;
  style?: React.CSSProperties;
  onClick?: () => void;
};

const MenuClose = ({ className, color, style, onClick }: MenuCloseProps): JSX.Element => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
      style={style}
      onClick={onClick}>
      <g clipPath="url(#clip198)">
        <path
          d="M0.482422 15.5031L15.5031 0.482422"
          stroke={color}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
        <path
          d="M15.5031 15.5031L0.482422 0.482422"
          stroke={color}
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip198">
          <rect width="16" height="16" fill={color} />
        </clipPath>
      </defs>
    </svg>
  );
};

MenuClose.defaultProps = {
  color: 'black',
};

export default MenuClose;
